import React, { useEffect, useState } from 'react';
import '../../../styles/introduction.css';
import GreyIconSet from './GreyIconSet';
import ImageBorderSet from './ImageBorderSet';
import BlueBorderSet from './BlueBorderSet';
import MosilerPartnerStep from './MosilerPartnerStep';
import MosilerScheduleExample from './MosilerScheduleExample';
import MonthlyMosilerTable from './MonthlyMosilerTable';
import ServicePatternSchedule from './ServicePatternSchedule';
import MainCalendar from './MainCalendar';
import ReviewSet from './ReviewSet';
import { MAIN_QUESTION_LIST, MAIN_REVIEW_SAMPLE_1, MAIN_REVIEW_SAMPLE_2 } from '../../../utils/constant';
import MainQuestion from './MainQuestion';
import YearlyMosilerProductTable from './YearlyMosilerProductTable';
import MonthlyMosilerProductTable from './MonthlyMosilerProductTable';
import PromotionProductTable from './PromotionProductTable';
import Input from '../../atoms/Input';
import classNames from 'classnames';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import { useForm } from 'react-hook-form';
import qs from 'qs'
import { setEstimatePageIntroduce } from '../../../lib/EstimateApi';
import { SendSlackIntroduce } from '../../../lib/SlackApi';
import { uploadTxt } from '../../../lib/MsAzureApi';
import { getUserInfoEncrypt } from '../../../lib/Util';
import { SendEztoc2, SendEztoc4 } from '../../../lib/EztocApi';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';
import moment from 'moment';


const MonthlyMosiler = () => {
	const {
		register,
		handleSubmit,
		setValue,
		setError,
		formState: { errors },
	} = useForm();

	const [inflow, setInflow] = useState()
	const navigate = useNavigate()
	useEffect(() => {
		const qsData = qs.parse(document.location.search, { ignoreQueryPrefix: true })?.inflow
		if (qsData === undefined || qsData.length <= 0) {
			setInflow('알수없음')
		}
		else {
			setInflow(qsData)
		}

		const appName = qs.parse(document.location.search, { ignoreQueryPrefix: true })?.name
		const appPhone = qs.parse(document.location.search, { ignoreQueryPrefix: true })?.phone
		const appEmail = qs.parse(document.location.search, { ignoreQueryPrefix: true })?.email

		setValue('name', appName ?? '')
		setValue('phone', appPhone ?? '')
		setValue('email', appEmail ?? '')
	}, [])

	// 폼 제출 처리 함수
	const onSubmit = async (data) => {
		if (data?.name?.length >= 20) {
			setError('name', {
				type: 'focus',
				name: 'name',
				message: '이름을 20자 이내로 입력해주세요'
			}, { shouldFocus: true });
			
			return;
		}

		
		// 데이터 세팅
		var reqObj = {
			UserName: data.name,
			PhoneNumber: data.phone,
			EmailAddress: data.email,
			Inflow: inflow
		}

		// 데이터 암호화
		var unicodeStr = encodeURIComponent(JSON.stringify(reqObj));
		var encrypt = btoa(unicodeStr);


		// 구글 시트에 저장
		setEstimatePageIntroduce({
			UserName: data.name,
			UserPhone: data.phone,
			UserEmail: data.email
		}, inflow);



		// slack 발송
		SendSlackIntroduce(reqObj)

		// Azure Storage에 저장
		uploadTxt(moment().format('yyyy-MM-DD hh:mm:ss') + '\n\n' + JSON.stringify(document.location) + '\n\n' + navigator.userAgent + '\n\ntmpInflow : ' + inflow + '\n\n' + JSON.stringify(reqObj));
		localStorage.setItem('token0', getUserInfoEncrypt(reqObj));

		// 소개서1 알림톡은 최초 1번만 발송.
		let tmpeztocFlag = localStorage.getItem('eztocFlag');
		if (tmpeztocFlag === null) {
			SendEztoc4({
				eztocType: 'introduce',
				UserName: reqObj.UserName,
				UserPhone: reqObj.PhoneNumber,
				inflow: inflow
			})
			localStorage.setItem('eztocFlag', 1);
		}


		/*
		// 구현 함수
		setEstimatePageIntroduce
		SendSlackIntroduce
		uploadTxt
		getUserInfoEncrypt
		SendEztoc2



				// 데이터 세팅
		var reqObj = {
			UserName: userInfo.UserName,
			PhoneNumber: userInfo.UserPhone,
			EmailAddress: userInfo.UserEmail,
			Inflow: tmpInflow
		}

		// 데이터 암호화
		var unicodeStr = encodeURIComponent(JSON.stringify(reqObj));
		var encrypt = btoa(unicodeStr);

		var estimateBaseUrl = ''
	    

		if (tmpInflow === 'mmpromo') {
			estimateBaseUrl = "https://mmpromo.mosiler.com"
		}
		else {
			estimateBaseUrl = "https://newestimate2.mosiler.com"
		}

		// URL 세팅
		var finalUrl =
			estimateBaseUrl +
			"/booking/subscription" +
			"?estimateinfo=" + encrypt +
			"&inflow=" + tmpInflow;

		// 새창 띄움(?)
		window.open(finalUrl, '_blank')


		// 로딩 띄우기
		setLoading(true)

		// 구글 시트에 저장
		await setEstimatePageIntroduce(userInfo, tmpInflow);

		// slack 발송
		await SendSlackIntroduce(reqObj)

		// Azure Storage에 저장
		await uploadTxt(moment().format('yyyy-MM-DD hh:mm:ss') + '\n\n' + JSON.stringify(document.location) + '\n\n' + navigator.userAgent + '\n\ntmpInflow : ' + tmpInflow + '\n\n' + JSON.stringify(userInfo));
		localStorage.setItem('token0', getUserInfoEncrypt(userInfo));       

		// 소개서1 알림톡은 최초 1번만 발송.
		let tmpeztocFlag = localStorage.getItem('eztocFlag');
		if (tmpeztocFlag === null) {
			if (tmpInflow === 'mmpromo') {
				await SendEztoc3({
					eztocType: 'introduce',
					UserName: userInfo.UserName,
					UserPhone: userInfo.UserPhone,
					inflow: tmpInflow
				})
			}
			else {
				await SendEztoc2({
					eztocType: 'introduce',
					UserName: userInfo.UserName,
					UserPhone: userInfo.UserPhone,
					inflow: tmpInflow
				})
			}
		    
			localStorage.setItem('eztocFlag', 1);
		}       

		setLoading(false)
		
		*/


		if (inflow === 'homepage_auto') {
			window.open(`https://newestimate2.mosiler.com${URLS.INTRODUCTION_MONTHLY_MOSILER}?inflow=${inflow}&estimateinfo=${encrypt}`, '_blank')
		}
		else {
			// alert(`TEST\n이름: ${data.name} \n이메일: ${data.email} \n연락처: ${data.phone}\n인입정보:${inflow}`);
			navigate(`${URLS.INTRODUCTION_MONTHLY_MOSILER}?inflow=${inflow}&estimateinfo=${encrypt}`)
		}


	};
	return (
		<>
			{/* <div className='mobile mobile-nav'>
				<div className='header'>
					<div></div>
					<span className='title'>상품소개</span>
					<Button styleType={'icon'} classes={'menu'}>
						<Icon icon={'menu'} />
					</Button>
				</div>
			</div> */}
			<div style={{overflowX: 'hidden'}}>
				<div className={'main-banner'}>
					<div className='title-wrap'>
						<span className='title'>
							<span>월간 모시러</span>
							<span>시간제 수행기사 서비스</span>
							<span>구독형 프로그램</span>
						</span>
					</div>
				</div>
				<div className='main-content'>
					<div className='section'>
						<div className='section-subtitle'>
							월간 모시러는 수행기사 서비스를
							<br className='mobile' /> 가장 효율적으로
							<br className='pc' />
							이용할 수 있는
							<br className='mobile' /> 구독 프로그램입니다.
						</div>
						<div className='flex-set-3'>
							<GreyIconSet iconName={'icon-schedule'} title={'월 단위 자유로운 이용'} subtitle={'최소 1개월부터 필요한 기간만큼 선택하여 이용할 수 있습니다.'} />
							<GreyIconSet iconName={'icon-pay'} title={'관리비 포함 합리적 요금'} subtitle={'드라이버 보험, 식비 등 관리 비용에 대한 고민없이 이용이 가능합니다.'} />
							<GreyIconSet iconName={'icon-prefer-driver'} title={'선호하는 드라이버 선택'} subtitle={'고객 성향에 맞는 최적의 드라이버를 제안해 드립니다.'} />
						</div>
					</div>
					<div className='section'>
						<div className='section-subtitle'>
							모시러는 이미 B2B 프리미엄 <br className='mobile' />
							기사 서비스로 국내 자산가들의 열렬한 <br className='mobile' /> <br className='pc' />
							성원을 받아 왔습니다. 프리미엄의 특성상, <br className='mobile' />
							작지만 큰 서비스의 차이를 <br className='pc' />
							만드는 <br className='mobile' />
							모시러의 철학이 성공을 만들어 냈습니다.
						</div>
						<div className='flex-set-3'>
							<ImageBorderSet imageName={'cars'} />
							<ImageBorderSet imageName={'certification'} />
							<ImageBorderSet imageName={'tada'} />
						</div>
					</div>
					{/* <div className='section'>
					<div className='section-title'>
						월간 모시러
						<br />
						파트너
					</div>
					<div className='flex-set-3'>
						<BlueBorderSet>
							<span>
								<strong>기사 및 비서의 역할을 수행</strong>해 주시는 분들을 파트너라 칭합니다.
							</span>
						</BlueBorderSet>
						<BlueBorderSet>
							<span>
								<strong>2,453명의 프리랜서</strong>들로 구성된 파트너들이 고객님들을 모십니다.
							</span>
						</BlueBorderSet>
						<BlueBorderSet>
							<span>
								<strong>영어, 일본어, 중국어 등</strong> 외국어 가능 파트너가 운행 중입니다.
							</span>
						</BlueBorderSet>
					</div>
				</div> */}
					<div className='section'>
						<div className='section-subtitle'>
							모시러의 자체 검증 시스템을 통과한 <br className='mobile' />
							드라이버들이 서비스를 제공합니다. <br className='mobile' />
							<br className='pc' />
							의전 교육 뿐만 아니라 범죄 이력 조회 및<br className='mobile' /> 고객 비밀 유지 서약서 작성 등 <br className='pc' />
							까다로운 <br className='mobile' />
							과정을 거쳐야만 모시러의 파트너가 될 수 <br className='mobile' />
							있습니다.
						</div>
						<MosilerPartnerStep />
					</div>
					<div className='section'>
						<div className='section-title mb-32'>
							월간 모시러
							<br />
							이용 예시
						</div>
						<div className='section-subtitle mb-120 mmb-40'>
							월간 모시러 서비스는 고정 고용 없이
							<br className='mobile' /> 월마다 원하는 기간에 편리하게 <br className='pc' />
							이용 <br className='mobile' />
							가능하며, 필요 시 스케줄을 유연하게
							<br className='mobile' /> 조정할 수 있습니다.
						</div>
						<MosilerScheduleExample />
					</div>
					<div className='section'>
						<div className='section-title'>
							월간 모시러
							<br />
							장점
						</div>
						<div className='flex-set-3'>
							<GreyIconSet iconName={'icon-hire'} title={'고용하기'} subtitle={`퇴직금 등의<br/> 부대비용 부담없이`} />
							<GreyIconSet iconName={'icon-control'} title={'관리하기'} subtitle={'주 52시간 동안,<br/>인력관리 걱정없이'} />
							<GreyIconSet iconName={'icon-adjust'} title={'맞춤제안'} subtitle={'필요에 알맞게<br/>스케줄 커스터마이징'} />
						</div>
					</div>
					{/* <div className='section-wrap'>
					<div className='section'>
						<div className='section-subtitle'>
							주 52시간 제약없이 자유롭게
							<br />
							수행기사 서비스 이용이 가능합니다.
						</div>
						<MonthlyMosilerTable />
					</div>
				</div> */}
					{/* <div className='section'>
					<div className='section-subtitle'>
						서비스 이용은 이용 패턴에 따라
						<br />
						스케줄링이 가능합니다.
					</div>
					<ServicePatternSchedule />
				</div> */}
					{/* <div className='section-wrap'>
					<div className='section'>
						<div className='section-subtitle'>
							서비스 이용 스케줄은 필요한 날에만
							<br />
							선택하여 사용이 가능합니다.
						</div>
						<MainCalendar />
					</div>
				</div> */}
					<div className='section'>
						<div className='grid-2-2'>
							<ImageBorderSet wrap={true} imageName={'driver'} overwrapDesc={'개인 사생활 노출 없이 자유롭게<br>이용하고 싶은 고객'} border={false} />
							<div className='title-large'>
								<span>월간 모시러</span>
								<span>이용 대상</span>
							</div>
							<ImageBorderSet wrap={true} imageName={'customer'} overwrapDesc={'수행기사 공백기간 동안 단기<br>서비스가 필요하신 고객'} border={false} />
							<ImageBorderSet wrap={true} imageName={'handshake'} overwrapDesc={'수행기사를 고용/관리 고민없이<br>편하게 쓰고 싶은 고객'} border={false} />
						</div>
					</div>
					{/* <div className='section'>
					<div className='grid-2-2 align-start gap-64 mgap-32'>
						<div>
							<div className='title-large left mb-68 mmb-40'>
								<span>월간 모시러</span>
								<span>이용 후기</span>
							</div>
							<ReviewSet title={MAIN_REVIEW_SAMPLE_1.title} reviewer={MAIN_REVIEW_SAMPLE_1.reviewer} contents={MAIN_REVIEW_SAMPLE_1.contents} pattern={MAIN_REVIEW_SAMPLE_1.pattern} />
						</div>
						<ReviewSet title={MAIN_REVIEW_SAMPLE_2.title} reviewer={MAIN_REVIEW_SAMPLE_2.reviewer} contents={MAIN_REVIEW_SAMPLE_2.contents} pattern={MAIN_REVIEW_SAMPLE_2.pattern} />
					</div>
				</div> */}
					{/* <div className='section'>
					<div className='grid-3 gap-20'>
						<div className='mt-32 mmt-0 grid-col gap-20'>
							<MainQuestion title={MAIN_QUESTION_LIST[0].title} contents={MAIN_QUESTION_LIST[0].contents} />
							<MainQuestion title={MAIN_QUESTION_LIST[1].title} contents={MAIN_QUESTION_LIST[1].contents} />
						</div>
						<div className='mt-100 mmt-0 grid-col gap-20'>
							<MainQuestion title={MAIN_QUESTION_LIST[2].title} contents={MAIN_QUESTION_LIST[2].contents} />
							<MainQuestion title={MAIN_QUESTION_LIST[3].title} contents={MAIN_QUESTION_LIST[3].contents} />
						</div>
						<div className='grid-col gap-20 with-title'>
							<div className='title-large mb-68 mmb-40'>
								<span>월간 모시러</span>
								<span>자주묻는 질문</span>
							</div>
							<MainQuestion title={MAIN_QUESTION_LIST[4].title} contents={MAIN_QUESTION_LIST[4].contents} />
							<MainQuestion title={MAIN_QUESTION_LIST[5].title} contents={MAIN_QUESTION_LIST[5].contents} />
						</div>
					</div>
				</div> */}
					<div className='section-wrap'>
						<div className='section'>
							<div className='section-title'>
								월간 모시러 <br />
								구독 상품
							</div>
							<MonthlyMosilerProductTable />
						</div>
					</div>
					<div className='section-wrap'>
						<div className='section'>
							<div className='section-title'>
								연간 모시러 <br />
								구독 상품
							</div>
							<YearlyMosilerProductTable />
						</div>
					</div>
					<div className='section-wrap'>
						<div className='section'>
							<div className='section-title'>프로모션 상품</div>
							<PromotionProductTable />
						</div>
					</div>

					<div className='section'>
						<div className='section-title'>
							아래 사항을 입력해서
							<br />
							지금 바로 소개서를 <br className='mobile' />
							확인해 보세요
						</div>
						<div className='form-wrap'>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className={classNames('input-set')}>
									<span className={classNames('txt-large')}>이름</span>
									<Input type={'text'} error={errors.name} {...register('name', { required: '이름을 입력해주세요.' })} placeholder={'성함을 입력해 주세요'} />
								</div>
								<div className={classNames('input-set')}>
									<span className={classNames('txt-large')}>연락처</span>
									<Input
										type={'tel'}
										error={errors.phone}
										{...register('phone', {
											required: '연락처를 입력해주세요.',
											pattern: {
												value: /^[0-9]{10,11}$/,
												message: '유효한 연락처를 입력해주세요.',
											},
										})}
										placeholder={'연락처를 입력해 주세요'}
									/>
								</div>
								<div className={classNames('input-set')}>
									<span className={classNames('txt-large')}>이메일 주소</span>
									<Input
										type={'text'}
										error={errors.email}
										{...register('email', {
											required: '이메일을 입력해주세요.',
											pattern: {
												value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
												message: '유효한 이메일 주소를 입력해주세요.',
											},
										})}
										placeholder={'이메일을 입력해 주세요'}
									/>
								</div>

								<Button type={'submit'} styleType={'secondary'} classes={'bold txt-large'}>
									소개서 확인하기
								</Button>
							</form>
						</div>
					</div>

				</div>
			</div>

		</>
	);
};

export default MonthlyMosiler;
